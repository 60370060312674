var mobileBreak = 992;

function resetHeaderMenu() {
    var header = $('#header'),
        menuHeader = $('#menuHeader'),
        menuHeaderToggler = $('#menuHeaderToggler'),
        headerLogo = $('#headerLogo');

    header.data('state','resetting');

    menuHeader.removeClass('show');
    menuHeaderToggler.removeClass('show');
    headerLogo.css({ 'pointer-events': 'all', 'opacity' : 1 });

    $('body').css({'overflow-y':'visible'});

    if( $(window).width() < mobileBreak ) {
        menuHeader.css({'left':(0 + menuHeader.outerWidth()),'display':'none'});
    } else {
        menuHeader.css({'left':'unset','display':'flex'});
    }

    header.data('state','default');
}

$('#menuHeaderToggler').click(function(){

    if( $(window).width() < mobileBreak ) {
        var menuHeader = $('#menuHeader'),
            header = $('#header'),
            search = $('#searchform'),
            headerLogo = $('#headerLogo');

        if( $(this).hasClass('show') ) {
            header.data('state','menuClosing');
            menuHeader.removeClass('show');
            $(this).removeClass('show');
            menuHeader.animate({left: (0 + menuHeader.outerWidth()) }, 300);
            search.animate({opacity: 1 }, 300);
            headerLogo.animate({opacity: 1 }, 300);
            $('body').css({'overflow-y':'visible'});

            setTimeout(function(){
                menuHeader.css({display:'none'});
                header.data('state','menuClosed');
                search.css({ 'pointer-events': 'all' });
                headerLogo.css({ 'pointer-events': 'all' });
            }, 300);
        } else {
            header.data('state','menuOpening');
            menuHeader.addClass('show');
            $(this).addClass('show');
            menuHeader.css({display:'flex'});
            $('body').css({'overflow-y':'hidden'})
            menuHeader.animate({left: (0) }, 300);
            search.animate({opacity: 0 }, 300);
            headerLogo.animate({opacity: 0 }, 300);

            setTimeout(function(){
                header.data('state','menuOpen');
                search.css({ 'pointer-events': 'none' });
                headerLogo.css({ 'pointer-events': 'none' });
            }, 300)
        }
    } else {
        resetHeaderMenu();
    }

    console.log(header.data('state'));
});

$(document).ready(function(){
    var header = $('#header');

    if( $(window).width() < mobileBreak ) {
        var menuHeader = $('#menuHeader');

        console.log();

        menuHeader.css({left: (0 + menuHeader.outerWidth()) });
    }

    header.data('state','default');
})

$(window).resize(function(){
    resetHeaderMenu();
})

$('.magnifier').click(function(){
    $(this).parent().toggleClass('active');
})
